// Google Tag Manager (GTM)

const scriptForHead = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-M24SRZM');";

export const loadGTM = () => {

    var head = window.document.getElementsByTagName('head');
    var body = window.document.getElementsByTagName('body');

    var scriptHead = window.document.createElement('script');
    scriptHead.type = 'text/javascript';
    scriptHead.innerHTML = scriptForHead;
    head[0].appendChild(scriptHead);

    var noscript = window.document.createElement('noscript');
    var iframe = window.document.createElement('iframe');
    iframe.src = "https://www.googletagmanager.com/ns.html?id=GTM-M24SRZM";
    iframe.width = 0;
    iframe.height = 0;
    iframe.style = "display:none;visibility:hidden";
    noscript.appendChild(iframe);
    body[0].insertBefore(noscript, body[0].firstChild);
    
}