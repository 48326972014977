import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AUTH_EXTERNAL} from "../store/types/auth";
import {getProfile} from "../store/actions/auth";

const useUrlToken = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const dispatch = useDispatch();
  const isAuthLoading = useSelector(state => state.auth.isAuthLoading);

  useEffect(() => {
    if(!isAuthLoading){
      if(urlParams.get('token')){
        dispatch({
          type: AUTH_EXTERNAL,
          payload: {
            tokens: {
              access_token: urlParams.get('token'),
              refresh_token: null,
            },
            remember: true,
            isUserLoading: true,
            externalAuth : true,
          },
        });

        getProfile()(dispatch);
      }
    }
  }, [isAuthLoading]);
}

export default useUrlToken;
