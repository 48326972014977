import OnboardingHeader from "../OnboardingHeader/OnboardingHeader";
import React, {useMemo} from "react";
import './OnboardingStepDone.scss';
import PropTypes from 'prop-types';
import OnboardingEndImage from "../../assets/images/onboarding-end.svg";
import OnboardingEndImagePending from "../../assets/images/onboarding-end.svg"; //TODO: Pedir imagen de pendiente
import {Button} from "react-bootstrap";
import ButtonCustom from "../forms/ButtonCustom";
import {useSelector} from "react-redux";

const OnboardingStepDone = ({onboardingData, onDone}) => {
  const ready = useMemo(() => onboardingData.user_data.ready, [onboardingData]);
  const redirect_url = useSelector(state => state.external.redirect_url);
  return (
    <div className="OnboardingStepDone">
      {
        ready ? (
          <>
            <div className="">
              <img className="OnboardingStepDone__end_icon" src={OnboardingEndImage}/>
            </div>
            <OnboardingHeader title="Registro enviado">
              Tus datos se registraron correctamente. <br/>
              Revisá tu mail, te vamos a explicar como hacer para operar con decreditos.
            </OnboardingHeader>
            <ButtonCustom
              size="lg"
              block={true}
              variant="primary"
              onClick={() => onDone && onDone(onboardingData)}
            >
              {redirect_url ? 'Volver al sitio de origen' : 'Ir al menú principal'}
            </ButtonCustom>
          </>
        ) : (
          <>
            <div className="">
              <img className="OnboardingStepDone__end_icon" src={OnboardingEndImagePending}/>
            </div>
            <OnboardingHeader title="Registro pendiente">
              Tus datos se registraron correctamente. <br/>
              Proximamente nos contactaremos para habilitar tu cuenta.
            </OnboardingHeader>
            <ButtonCustom
              size="lg"
              block={true}
              variant="primary"
              onClick={() => onDone && onDone(onboardingData)}
            >
              Entendido
            </ButtonCustom>
          </>
        )
      }
    </div>
  )
}

OnboardingStepDone.propTypes = {
  onboardingData: PropTypes.object.isRequired,
  onDone: PropTypes.func,
}

export default OnboardingStepDone;
