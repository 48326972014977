import {Button, Col, FormGroup, Row} from "react-bootstrap";
import InputCustom from "../forms/InputCustom";
import React, {useEffect} from "react";
import './UserCompleteForm.scss';
import {useFormik} from "formik";
import * as Yup from "yup";
import SelectCustom from "../forms/SelectCustom";
import commonAPI from "../../api/commonAPI";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import PhoneInputCustom from "../forms/PhoneInputCustom";
import isHubspotValid from "../../helpers/hubspot-email-validation";

const UserCompleteForm = ({
                            onSubmit,
                            initialValues = {},
                            submitLabel,
                            loading = false,
                            readonly = false,
                            platforms = null,
                            platformRoles = null,
                            serverError
  }) => {
  const checkEmail = useDebouncedCallback( email => {
    return commonAPI.checkEmailUnavailable(email);
  }, 500,[commonAPI.checkEmailUnavailable])

  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      user_roles: [],
      ...initialValues,
      enabled: (initialValues.enabled || initialValues.enabled === undefined) ? '1' : '',
      personal_data: {
        first_name: '',
        last_name: '',
        dni: '',
        gender: null,
        ...initialValues.personal_data,
      },
    },
    onSubmit: values => onSubmit && onSubmit(values),
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .required('Obligatorio')
        .email('Email invalido')
        .test(
          'is-available',
          'El email no esta disponible',
          async email => initialValues.email || ((await checkEmail(email))?.data === false)
        )
        .test(
          'is-hubspot-valid',
          'Email invalido.',
          isHubspotValid
        ),
      phone: Yup.string()
        .required('Obligatorio')
        .matches(
          "^0(\\d{2,4})\\ 15(\\d{4,8})$",
          "Debe ingresar codigo de area y numero de celular"
        ),
      user_roles: Yup.array().of(Yup.object().shape({
        role_id: Yup.number(),
        platform_id: Yup.number().required('Obligatorio'),
      })).test(
        'is-available',
        'Si desea activar Seguros, debe tambien activar Agencias',
        (user_roles) => {
          //TODO: AS of now S1 doesnt allow access to insurance without access to agencies
          const agenciesPlatformId = platforms.find(p => p.key === 'agencies')?.id || null;
          const insurancePlatformId = platforms.find(p => p.key === 'insurance')?.id || null;

          const selectedRoleForAgencies = user_roles.find(r => r.platform_id === agenciesPlatformId);
          const selectedRoleForInsurance = user_roles.find(r => r.platform_id === insurancePlatformId);

          if(insurancePlatformId && selectedRoleForInsurance && selectedRoleForInsurance.role_id){
            if(!agenciesPlatformId || !selectedRoleForAgencies || !selectedRoleForAgencies.role_id){
              return false;
            }
          }
          //TODO: AS of now S1 doesnt allow access to insurance without access to agencies

          return true;
        }
      ),
      personal_data: Yup.object().shape({
        first_name: Yup.string().required('Obligatorio'),
        last_name: Yup.string().required('Obligatorio'),
        dni: Yup.string().length(8, '8 Digitos').required('Obligatorio'),
        gender: Yup.mixed().oneOf(['M', 'F'], 'Debe seleccionar').required('Obligatorio'),
      }),
    }),
  });

  useEffect(() => {
    formik.setFieldValue('user_roles', platforms ? platforms.map((platform) => {
      return {
        platform_id: platform.id,
        role_id: formik.values.user_roles.find(a => a.platform_id === platform.id)?.role_id || '',
      };
    }) : [])
  }, [platforms]);

  return (
    <form className="UserCompleteForm" onSubmit={formik.handleSubmit}>
      <Row className="mb-2">
        <Col md={4}>
          <FormGroup>
            <InputCustom
              value={formik.values.email}
              name="email"
              onChange={formik.handleChange}
              onKeyUp={formik.handleChange}
              title="Email"
              readOnly={readonly || loading || initialValues.email}
              error={formik.touched.email && formik.errors.email}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <PhoneInputCustom
              title="Celular"
              autoComplete="off"
              maxlength_prefix="4"
              maxlength_number="8"
              start_number="15"
              start_prefix="0"
              placeholder_number="00000000"
              placeholder_prefix="11"
              onValueChange={formik.setFieldValue.bind(null, 'phone')}
              name="phone"
              value={formik.values.phone}
              id="phone"
              disabled={loading || readonly}
              error={formik.touched.phone && formik.errors.phone}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={4}>
          <FormGroup>
            <InputCustom
              value={formik.values.personal_data.first_name}
              name="personal_data.first_name"
              onChange={formik.handleChange}
              title="Nombre"
              readOnly={readonly || loading}
              error={formik.touched.personal_data?.first_name && formik.errors.personal_data?.first_name}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <InputCustom
              value={formik.values.personal_data.last_name}
              name="personal_data.last_name"
              onChange={formik.handleChange}
              title="Apellido"
              readOnly={readonly || loading}
              error={formik.touched.personal_data?.last_name && formik.errors.personal_data?.last_name}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4}>
          <FormGroup>
            <InputCustom
              value={formik.values.personal_data.dni}
              name="personal_data.dni"
              onChange={formik.handleChange}
              title="DNI"
              readOnly={readonly || loading || formik.values.personal_data.identity_confirmed_at}
              error={formik.touched.personal_data?.dni && formik.errors.personal_data?.dni}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <SelectCustom
              value={formik.values.personal_data.gender}
              name="personal_data.gender"
              onChange={formik.handleChange}
              title="Genero"
              disabled={readonly || loading || formik.values.personal_data.identity_confirmed_at}
              error={formik.touched.personal_data?.gender && formik.errors.personal_data?.gender}
              placeholder="Seleccione"
            >
              <option value="M">
                Masculino
              </option>
              <option value="F">
                Femenino
              </option>
            </SelectCustom>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <SelectCustom
              value={formik.values.enabled}
              name="enabled"
              onChange={formik.handleChange}
              title="Habilitado"
              disabled={readonly || loading}
              error={formik.touched.enabled && formik.errors.enabled}
              placeholder="Seleccione"
            >
              <option value="1">
                Habilitado
              </option>
              <option value="">
                Deshabilitado
              </option>
            </SelectCustom>
          </FormGroup>
        </Col>
      </Row>

      <hr/>

      <Row>
        {formik.values.user_roles.map((platform_role, i) => {
          const platform = platforms.find(p => p.id === platform_role.platform_id);
          const roles = platformRoles ? platformRoles.filter(pr => pr.platform_id === platform.id) : [];

          return (
            <Col md={4} key={platform_role.platform_id}>
              <FormGroup>
                <SelectCustom
                  onChange={formik.handleChange}
                  title={platform.name}
                  name={`user_roles.${i}.role_id`}
                  value={formik.values.user_roles?.[i]?.role_id}
                  id={`user_roles.${i}.role_id`}
                  error={formik.errors?.user_roles?.[i]?.role_id}
                  disabled={readonly || loading}
                >
                  <option value="">
                    Sin acceso
                  </option>
                  {roles.map(role => (
                    <option value={role.id} key={role.id}>
                      Acceso como {role.name}
                    </option>
                  ))}
                </SelectCustom>
              </FormGroup>
            </Col>
          );
        })}
        <Col xs={12}>
          {formik.touched.user_roles && formik.errors.user_roles && (
            <p className="error">
              {formik.touched.user_roles && formik.errors.user_roles}
            </p>
          )}
        </Col>
      </Row>

      {serverError && (<p className="server-error">{serverError}</p>)}

      {!readonly && (
        <div>
          <Button
            type="submit"
            className="UserCompleteForm__submit"
            size="lg"
            block={true}
            variant="primary"
            disabled={loading}
          >
            {loading ? 'Cargando...' : (submitLabel || 'Guardar')}
          </Button>
        </div>
      )}
    </form>
  );
}

UserCompleteForm.propTypes = {

}

export default UserCompleteForm;