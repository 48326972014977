import OnboardingHeader from "../OnboardingHeader/OnboardingHeader";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import './OnboardingStepPersonalDataVerification.scss';
import PersonalDataRenaperVerificationForm from "../PersonalDataRenaperVerificationForm/PersonalDataRenaperVerificationForm";
import useIsMobile from "../../hooks/useIsMobile";
import PropTypes from 'prop-types';
import onboardingAPI from "../../api/onboardingAPI";
import PersonalDataVerazVerificationForm from "../PersonalDataVerazVerificationForm/PersonalDataVerazVerificationForm";
import AsyncContent from "../AsyncContent/AsyncContent";
import useAsyncData from "../../hooks/useAsyncData";
import PersonalDataMatiVerificationForm from "../PersonalDataMatiVerificationForm/PersonalDataMatiVerificationForm";
import {setIn} from "formik";
import useInterval from "../../hooks/useInterval";

const OnboardingStepPersonalDataVerification = ({onDone, onboardingData}) => {
  const isMobile = useIsMobile();

  /* Mati */
  const [loadMatiStatus, matiStatus, matiStatusLoading, matiStatusError] = useAsyncData(async ({setData, setError}) => {
    try{
      const response = await onboardingAPI.getMatiStatus();
      setData(response.data.data);
      return response;
    }catch(e){
      setError('Ocurrio un error');
    }
  });

  useEffect(() => {
    const refreshInterval = setInterval(loadMatiStatus, 10000);
    loadMatiStatus();

    return () => {
      clearInterval(refreshInterval);
    }
  }, []);

  const submitMatiHandler = useCallback(async () => {
    const response = await onboardingAPI.getOnboarding();
    onDone && onDone(response.data.data);
  }, [onDone]);
  /* Mati */

  /* Veraz */
  const [loadAttempt, attempt, attemptLoading, attemptError] = useAsyncData(async ({setData, setError}) => {
    try{
      const response = await onboardingAPI.getVerazQuestions();
      setData(response.data);
      return response;
    }catch(e){
      if(e.response?.status === 400){
        setError('Su DNI fue bloqueado');
      }else{
        setError('Ocurrio un error');
      }
    }
  });

  const [submitVerazHandler, _, verazLoading, verazError] = useAsyncData(async ({setLoading, setError}, values) => {
    try{
      const response = await onboardingAPI.verifyVerazIdentity(values);
      onDone && onDone(response.data.data, true);
    }catch(e){
      if(e.response?.status === 400){
        onDone && onDone(e.response.data.data, false);
        //setAttempts(attempts => attempts + 1);
      }else{
        setError('Ocurrio un error');
      }
    }
  }, [onDone]);
  /* Veraz */

  /* Renaper */
  const [submitRenaperHandler, __, renaperLoading, renaperError] = useAsyncData(async ({setError}, values) => {
    try{
      const formData = new FormData();
      formData.append('selfie_photo', values.selfie_photo);
      formData.append('dni_front_photo', values.dni_front_photo);
      formData.append('dni_back_photo', values.dni_back_photo);

      const response = await onboardingAPI.verifyRenaperIdentity(formData);
      onDone && onDone(response.data.data, true);
    }catch(e){
      if(e.response?.status === 400){
        //setAttempts(attempts => attempts + 1);
      }else{
        setError('Ocurrio un error');
      }
    }
  }, [onDone]);
  /* Renaper */

  const headerSubtitle = useMemo(() => {
    if(matiStatus){
      if(matiStatus.waiting_for_auto || matiStatus.waiting_for_manual > 0){
        return '';
      }
      if (matiStatus.can_retry && matiStatus.attempts === 0) return '';

      if(matiStatus.validated === true){
        return 'Identidad validada.';
      }
    }

    return 'A continuacion te pediremos que valides tu identidad';
  }, [matiStatus, matiStatus?.validated, matiStatus?.rejected_attempts]);

  return (
    <div className="OnboardingStepPersonalDataVerification">
      <>
        <OnboardingHeader title="Valida tu identidad">
          {headerSubtitle}
        </OnboardingHeader>
        {matiStatus &&
          <PersonalDataMatiVerificationForm
            nextStepLabel="Continuar al próximo paso"
            submitLabel="Continuar al próximo paso"
            singleStep={!isMobile}
            metadata={{email: onboardingData.user_data.email, dni: onboardingData.personal_data.dni }}
            matiStatus={matiStatus}
            reloadMatiStatus={loadMatiStatus}
            matiStatusLoading={matiStatusLoading}
            onSubmit={submitMatiHandler}
          />
        }
        <AsyncContent
          hasError={!!matiStatusError}
          isLoading={matiStatusLoading && !matiStatus}
          errorMessage={matiStatusError}
          onRetryHandler={loadMatiStatus}
        />
      </>
      {/*
      {attempts <= 2 ? (
        <>
          <OnboardingHeader title="Valida tu identidad">
            {attempts === 0
              ? "A continuacion te pediremos que tomes unas fotos para validar tu identidad."
              : "No pudimos validar con las fotos anteriores, intenta nuevamente."}
          </OnboardingHeader>
          <PersonalDataRenaperVerificationForm
            onSubmit={submitRenaperHandler}
            nextStepLabel="Continuar al próximo paso"
            submitLabel="Continuar al próximo paso"
            singleStep={!isMobile}
          />
        </>
      ) : (
        <>
          <OnboardingHeader title="Valida tu identidad">
            No pudimos validar tu identidad con las fotos, a continuacion vas a poder hacerlo
            respondiendo unas preguntas.
          </OnboardingHeader>
          <AsyncContent
            hasError={!!attemptError}
            errorMessage={attemptError}
            isLoading={attemptLoading}
            onRetryHandler={loadAttempt}
          >
            <PersonalDataVerazVerificationForm
              onSubmit={submitVerazHandler}
              nextStepLabel="Continuar al próximo paso"
              submitLabel="Continuar al próximo paso"
              singleStep={!isMobile}
              questions={attempt?.questions || null}
              loading={verazLoading}
              serverError={verazError}
            />
          </AsyncContent>
        </>
      ) }
      */}
    </div>
  )
}

OnboardingStepPersonalDataVerification.propTypes = {
  onDone: PropTypes.func.isRequired,
  onboardingData: PropTypes.object.isRequired,
}

export default OnboardingStepPersonalDataVerification;