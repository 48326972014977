import React from 'react';
import ReactDOM from 'react-dom';
import './assets/styles/theme.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from "@sentry/react";
import Spinner from "./components/Spinner/Spinner";
import { PersistGate } from 'redux-persist/integration/react';
import config from './config/index';

import { store, persistor } from "./store";
import { Provider } from "react-redux";

import { loadGTM } from './config/gtm';

if (config.enviroment && config.enviroment === 'production'){
  loadGTM();
}

if(config.sentry_dsn){
  Sentry.init({ dsn: config.sentry_dsn });
}

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"Ocurrio un error"}>
      <Provider store={store}>
        <PersistGate loading={<Spinner size={300}/>} persistor={persistor}>
          <App />
        </PersistGate>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
