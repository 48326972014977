import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {Button, Col, Form, Row} from "react-bootstrap";
import './BusinessDataForm.scss';
import SelectCustom from "../forms/SelectCustom";
import InputCustom from "../forms/InputCustom";
import useStepper from "../../hooks/useStepper";
import PropTypes from 'prop-types';
import onboardingAPI from '../../api/onboardingAPI';
import ButtonCustom from "../forms/ButtonCustom";
import PhoneInputCustom from "../forms/PhoneInputCustom";
import LocationSearchModal from "../LocationSearchModal/LocationSearchModal";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import commonAPI from "../../api/commonAPI";
import {useSelector} from "react-redux";

const BusinessDataForm = ({
                            onSubmit,
                            onStepChange,
                            initialValues = {},
                            submitLabel,
                            nextStepLabel,
                            loading = false,
                            readonly = false,
                            businessActivities,
                            fiscalConditions,
                            businessTypes,
                            provinces,
                            singleStep = false,
                            serverError,
                            existingBusinessId,
                          }) => {
  const [locationSearchOpen, setLocationSearchOpen] = useState(false);
  const [locationFormVisible, setLocationFormVisible] = useState(!!initialValues.address_street);

  const checkBusinessName = useDebouncedCallback(commonAPI.checkBusinessNameUnavailable, 500,[]);

  const stepper = useStepper({
    initialStep: 1,
    onStepChange: onStepChange,
    onFinish: onSubmit,
    steps: 3,
    singleStepMode: singleStep,
  });

  const formik = useFormik({
    initialValues: {
      type_id: null,
      type_description: '',
      name: '',
      address_street: '',
      address_number: '',
      address_floor: '',
      address_apt: '',
      address_locality: '',
      address_province_id: '',
      address_postal_code: '',
      phone: '',
      activity_id: null,
      fiscal_condition_id: null,
      cuit: '',
      ...initialValues,
    },
    onSubmit: stepper.submitHandler,
    validationSchema: stepper.validationSchema({
      1: {
        type_id: Yup.number().nullable().required('Obligatorio'),
        type_description: Yup.string(),
      },
      2: {
        name: Yup.string()
          .required('Obligatorio')
          .test(
            'is-available',
            'El nombre no esta disponible',
            async businessName => ((await checkBusinessName(businessName, existingBusinessId))?.data === false)
          ),
        address_street: Yup.string().required('Obligatorio'),
        address_number: Yup.number().required('Obligatorio').integer(),
        address_floor: Yup.string().nullable().max(4, 'Max 4 digitos'),
        address_postal_code: Yup.string()
          .required()
          .max(6, 'Max 6 digitos')
          .min(3, 'Min 3 digitos')
          .matches(
            "^[\\d]+$",
            "Solo numeros"
          ),
        address_apt: Yup.string().nullable().max(4, 'Max 4 digitos'),
        address_locality: Yup.string().required('Obligatorio'),
        address_province_id: Yup.number().required('Obligatorio'),
        phone: Yup.string()
          .required('Obligatorio')
          .matches(
            "^0(\\d{2,4})\\ (\\d{6,10})$",
            "Debe ingresar codigo de area y numero de telefono"
          ),
        activity_id: Yup.number().nullable()/*.required('Obligatorio')*/,
      },
      3: {
        fiscal_condition_id: Yup.number().nullable().required('Obligatorio'),
        cuit: Yup.string().matches(/\b\d{11}\b/, 'Invalido').when('fiscal_condition_id', (fc_id, schema) => {
          return fiscalConditions?.find(fc => fc.id === parseInt(formik.values.fiscal_condition_id))?.has_cuit
            ? schema.required('Debe cargar el CUIT')
            : schema;
        }),
      }
    }),
  });

  const [cuitHelper, setCuitHelper] = useState(null);

  const businessTypeHasDescription = useMemo(() => {
    const selectedBusinessType = businessTypes?.find(bt => bt.id === parseInt(formik.values.type_id));
    return !!selectedBusinessType?.requires_type_description;
  }, [formik.values.type_id, businessTypes]);

  useEffect(() => {
    if(!businessTypeHasDescription){
      formik.setFieldValue('type_description', '');
    }
  }, [businessTypeHasDescription]);

  useEffect(() => {
    setCuitHelper(null);

    if(formik.values.cuit && formik.values.cuit.length === 11){
      setCuitHelper('...');

      onboardingAPI.getBusinessName({cuit: formik.values.cuit})
                   .then(resp => setCuitHelper(resp.data.business_name))
                   .catch(e => setCuitHelper('Error'))
    }
  }, [formik.values && formik.values.cuit]);

  useEffect(() => {
    formik.errors.address_number && formik.touched.address_number && setLocationFormVisible(true);
  }, [formik.errors.address_number, formik.touched.address_number]);

  const fiscalConditionHasCuit = useMemo(() => {
    const fiscalCondition = fiscalConditions?.find(fc => fc.id === parseInt(formik.values.fiscal_condition_id));
    return !!fiscalCondition?.has_cuit;
  }, [formik.values.fiscal_condition_id, fiscalConditions]);

  useEffect(() => {
    if(!fiscalConditionHasCuit){
      formik.setFieldValue('cuit', '');
    }
  }, [fiscalConditionHasCuit]);

  const locationSearchHideHandler = useCallback(() => {
    setLocationSearchOpen(false);
    setLocationFormVisible(true);
  }, []);

  const locationSelectedHandler = useCallback(location => {
    setLocationFormVisible(true);

    formik.setFieldValue('address_street', location.street?.short_name || '');
    formik.setFieldValue('address_number', location.number?.short_name || '');
    formik.setFieldValue('address_floor', '');
    formik.setFieldValue('address_apt', '');
    formik.setFieldValue('address_locality', location.locality?.long_name || '');
    formik.setFieldValue(
      'address_postal_code',
      location.postal_code?.short_name ? (location.postal_code?.short_name.match(/([\d]{3,6})/)?.[1]) : '' //Removing letters
    );
    formik.setFieldValue('address_province_id',
      provinces.find(prov => prov.name === location.province?.long_name || '')?.id
    );
  }, []);

  return (
    <Form className="BusinessDataForm" onSubmit={formik.handleSubmit}>
      {stepper.isStepActive(1) && (
        <>
          <Form.Group>
            <SelectCustom
              title="Tipo de comercio"
              onChange={formik.handleChange}
              placeholder="Seleccione"
              name="type_id"
              value={formik.values.type_id}
              id="type_id"
              disabled={loading || readonly}
              error={formik.touched.type_id && formik.errors.type_id}
            >
              {businessTypes && businessTypes.map(bt => (
                <option key={bt.id} value={bt.id}>
                  {bt.name}
                </option>
              ))}
            </SelectCustom>
          </Form.Group>

          {businessTypeHasDescription && (
            <Form.Group>
              <InputCustom
                title="Otros"
                onChange={formik.handleChange}
                placeholder="Ingresa el tipo de tu comercio"
                name="type_description"
                value={formik.values.type_description}
                id="type_description"
                disabled={loading || readonly}
                error={formik.touched.type_description && formik.errors.type_description}
              />
            </Form.Group>
          )}
        </>
      )}

      {stepper.isStepActive(2) && (
        <>
          <Form.Group>
            <InputCustom
              title="Nombre de tu comercio"
              onChange={formik.handleChange}
              placeholder=""
              name="name"
              value={formik.values.name}
              id="name"
              disabled={loading || readonly}
              error={formik.touched.name && formik.errors.name}
            />
          </Form.Group>

          <div className="BusinessDataForm__address">
            <h6 className="text-center">Direccion</h6>

            <div className="BusinessDataForm__search_container">
              <Button variant="primary" onClick={() => setLocationSearchOpen(true)}>
                Buscar en mapa
              </Button>
              <LocationSearchModal
                show={locationSearchOpen}
                onHide={locationSearchHideHandler}
                onLocationSelected={locationSelectedHandler}
              />
            </div>

            {locationFormVisible && (
              <>
                <Row>
                  <Col sm={8}>
                    <Form.Group>
                      <InputCustom
                        title="Calle"
                        placeholder=""
                        onChange={formik.handleChange}
                        value={formik.values.address_street}
                        error={formik.touched.address_street && formik.errors.address_street}
                        name="address_street"
                        id="address_street"
                        disabled={loading || readonly}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={4}>
                    <Form.Group>
                      <InputCustom
                        title="Numero"
                        placeholder=""
                        onChange={formik.handleChange}
                        value={formik.values.address_number}
                        error={formik.touched.address_number && formik.errors.address_number}
                        name="address_number"
                        id="address_number"
                        disabled={loading || readonly}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xs={6}>
                    <Form.Group>
                      <InputCustom
                        title="Piso"
                        placeholder=""
                        onChange={formik.handleChange}
                        value={formik.values.address_floor}
                        error={formik.touched.address_floor && formik.errors.address_floor}
                        name="address_floor"
                        id="address_floor"
                        disabled={loading || readonly}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group>
                      <InputCustom
                        title="Depto"
                        placeholder=""
                        onChange={formik.handleChange}
                        value={formik.values.address_apt}
                        error={formik.touched.address_apt && formik.errors.address_apt}
                        name="address_apt"
                        id="address_apt"
                        disabled={loading || readonly}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group>
                  <InputCustom
                    title="Localidad"
                    placeholder=""
                    onChange={formik.handleChange}
                    value={formik.values.address_locality}
                    error={formik.touched.address_locality && formik.errors.address_locality}
                    name="address_locality"
                    id="address_locality"
                    disabled={loading || readonly}
                  />
                </Form.Group>
                <Form.Group>
                  <InputCustom
                    title="Codigo postal"
                    placeholder=""
                    onChange={formik.handleChange}
                    value={formik.values.address_postal_code}
                    error={formik.touched.address_postal_code && formik.errors.address_postal_code}
                    name="address_postal_code"
                    id="address_postal_code"
                    disabled={loading || readonly}
                  />
                </Form.Group>
                <Form.Group>
                  <SelectCustom
                    title="Provincia"
                    placeholder="Seleccione"
                    onChange={formik.handleChange}
                    value={formik.values.address_province_id}
                    error={formik.touched.address_province_id && formik.errors.address_province_id}
                    name="address_province_id"
                    id="address_province_id"
                    disabled={loading || readonly}
                  >
                    {provinces.map(province => (
                      <option key={province.id} value={province.id}>
                        {province.name}
                      </option>
                    ))}
                  </SelectCustom>
                </Form.Group>
              </>
            )}
          </div>

          <Form.Group>
            <PhoneInputCustom
              title="Particular/Celular"
              onValueChange={(val) => (formik.setFieldValue('phone', val))}
              placeholder=""
              name="phone"
              value={formik.values.phone}
              placeholder_prefix="11"
              maxlength_number={10}
              maxlength_prefix={4}
              start_prefix="0"
              start_number=""
              id="phone"
              disabled={loading || readonly}
              error={formik.touched.phone && formik.errors.phone}
            />
          </Form.Group>

          {/*<Form.Group>
            <SelectCustom
              title="Actividad"
              onChange={formik.handleChange}
              placeholder="Seleccione"
              name="activity_id"
              value={formik.values.activity_id}
              id="activity_id"
              disabled={loading || readonly}
              error={formik.touched.activity_id && formik.errors.activity_id}
            >
              {businessActivities && businessActivities.map(ac => (
                <option key={ac.id} value={ac.id}>
                  {ac.name}
                </option>
              ))}
            </SelectCustom>
          </Form.Group>*/}
        </>
      )}

      {stepper.isStepActive(3) && (
        <>
          <Form.Group>
            <SelectCustom
              title="¿Cual es la condicion fiscal de tu negocio?"
              onChange={formik.handleChange}
              placeholder="Seleccione"
              name="fiscal_condition_id"
              value={formik.values.fiscal_condition_id}
              id="fiscal_condition_id"
              disabled={loading || readonly}
              error={formik.touched.fiscal_condition_id && formik.errors.fiscal_condition_id}
            >
              {fiscalConditions && fiscalConditions.map(fc => (
                <option key={fc.id} value={fc.id}>
                  {fc.name}
                </option>
              ))}
            </SelectCustom>
          </Form.Group>

          {fiscalConditionHasCuit && (
            <Form.Group>
              <InputCustom
                title={cuitHelper ? `CUIT (${cuitHelper})` : 'CUIT'}
                onChange={formik.handleChange}
                placeholder=""
                name="cuit"
                value={formik.values.cuit}
                id="cuit"
                disabled={loading || readonly}
                error={formik.touched.cuit && formik.errors.cuit}
              />
            </Form.Group>
          )}
        </>
      )}

      {serverError && (<p className="server-error">{serverError}</p>)}

      {!readonly && (
        <div>
          <ButtonCustom
            type="submit"
            className="BusinessDataForm__submit"
            size="lg"
            block={true}
            variant="primary"
            loading={loading}
          >
            {stepper.isLastStep ? (submitLabel || 'Guardar') : (nextStepLabel || 'Continuar')}
          </ButtonCustom>
        </div>
      )}
    </Form>
  );
}

BusinessDataForm.propTypes = {
  onSubmit: PropTypes.func,
  onStepChange: PropTypes.func,
  initialValues: PropTypes.shape({

  }),
  submitLabel: PropTypes.string,
  nextStepLabel: PropTypes.string,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  singleStep: PropTypes.bool,
  serverError: PropTypes.string,
  existingBusinessId: PropTypes.oneOfType([
    () => null,
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  businessActivities: PropTypes.oneOfType([
    () => null,
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }))
  ]),
  fiscalConditions: PropTypes.oneOfType([
    () => null,
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
      has_cuit: PropTypes.bool.isRequired
    }))
  ]),
  businessTypes: PropTypes.oneOfType([
    () => null,
    PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }))
  ]),
}

export default BusinessDataForm;