import React, {useCallback, useEffect, useState} from "react";
import './PersonalDataMatiVerificationForm.scss';
import PropTypes from "prop-types";
import Spinner from "../Spinner/Spinner";
import ButtonCustom from "../forms/ButtonCustom";
import useInterval from "../../hooks/useInterval";

const PersonalDataMatiVerificationForm = ({
                                        onSubmit,
                                        initialValues = {},
                                        nextStepLabel,
                                        submitLabel,
                                        loading = false,
                                        readonly = false,
                                        onStepChange,
                                        singleStep = false,
                                        metadata,
                                        matiStatus,
                                        reloadMatiStatus,
                                        matiStatusLoading,
}) => {
  const [retry, setRetry] = useState(true);

  const [watcherActive, setActive] = useState(false);
  const [timeInSeconds, setSeconds] = useState(0);
  const [timeOut, setTimeOut] = useState(false);
  const [delay, setDelay] = useState(null);
  const retryInputHandler = useCallback(async () => {
    const newMatiStatus = await reloadMatiStatus();
    setRetry(true);
  }, [reloadMatiStatus]);


  const [validationErrors, setValidationErrors] = useState({
    'face_match_error'   : false,
    'rejected_dni' : false,
  });

  useEffect(() => {
    setTimeout(function(){
      if (watcherActive) {
        setDelay(1000);//milliseconds
        setSeconds(0);
      }
    }, 3000)

  }, [watcherActive]);

  useInterval( () => {
    let matiFrameIsClosed = document.getElementsByTagName("mati-frame")[0] === undefined;
    if (matiFrameIsClosed && watcherActive) {
      setSeconds((prevVal => prevVal+1));
    }
    if (watcherActive && timeInSeconds > 60 && matiFrameIsClosed) {
      setTimeOut(true);
      stopWatcher();
    }
  }, delay)

  useEffect(() => {
    setRetry(false);
  }, [matiStatus.waiting_for_input_count]);


  const mapError = (prevVal,{face_match_error = false, rejected_dni = false}) => {
    return {...prevVal,
      face_match_error : prevVal.face_match_error ? prevVal.face_match_error : face_match_error,
      rejected_dni : prevVal.rejected_dni ? prevVal.rejected_dni : rejected_dni
    };
  }

  useEffect(() => {
    if (matiStatus) {
      setValidationErrors(prevVal => {
        return mapError(prevVal, matiStatus);
      })
    }
  }, [matiStatus.rejected_dni, matiStatus.face_match_error]);


  const stopWatcher = () => {
    setActive(false);
    setSeconds(0);
    setDelay(null);
  }

  const onRejectedDni = () => {
    setValidationErrors( prevVal =>  ({...prevVal, rejected_dni : false}));

  }

  const onFaceMatchError = () => {
    setValidationErrors( prevVal => ({...prevVal, face_match_error : false}));
    setActive(true)
  }

  if (matiStatus.validated) {
    return (
      <div className="PersonalDataMatiVerificationForm">
        <p>Su identidad fue validada correctamente.</p>
        <ButtonCustom
          type="submit"
          className="PersonalDataMatiVerificationForm__submit"
          size="lg"
          block={true}
          variant="primary"
          loading={loading}
          onClick={onSubmit}
        >
          {submitLabel || 'Continuar'}
        </ButtonCustom>
      </div>
    )
  }

  if (matiStatus.fraud_attempt) {
    //Lo dejamos aqui clavado forever and ever
    return (
      <div className="PersonalDataMatiVerificationForm">
        <p>No pudimos validar tu identidad, por favor intenta nuevamente.</p>
        <div className="PersonalDataMatiVerificationForm__mati_container">
          <metamap-button
            clientid={matiStatus.client_id}
            flowId={matiStatus.flow_id}
            metadata={JSON.stringify(metadata)}
            onClick={() => setActive(true)}
          />
        </div>
      </div>
    )
  }

  if (validationErrors.rejected_dni && matiStatus.can_retry) {
    return (
      <div className="PersonalDataMatiVerificationForm">
        <p>El número de DNI no coincide con la foto ingresada. Por favor revisa los datos</p>
        <div className="PersonalDataMatiVerificationForm__mati_container">
          <metamap-button
            clientid={matiStatus.client_id}
            flowId={matiStatus.flow_id}
            metadata={JSON.stringify(metadata)}
            onClick={onRejectedDni}
          />
        </div>
      </div>
    )}

  if (validationErrors.face_match_error && matiStatus.can_retry) {
    return (
      <div className="PersonalDataMatiVerificationForm">
        <p>El DNI subido no coincide con la selfie. Por favor intentá nuevamente</p>
        <div className="PersonalDataMatiVerificationForm__mati_container">
          <metamap-button
            clientid={matiStatus.client_id}
            flowId={matiStatus.flow_id}
            metadata={JSON.stringify(metadata)}
            onClick={onFaceMatchError}
          />
        </div>
      </div>
    )}
      else if ((timeOut && !matiStatus.waiting_for_auto && !matiStatus.waiting_for_manual &&
        !validationErrors.rejected_dni && !validationErrors.face_match_error )) {

    return (
      <div className="PersonalDataMatiVerificationForm">
        <p>No pudimos validar tu identidad, lo haremos más tarde</p>
        <p>Puede continuar pero tendremos que revisar sus datos antes de darle acceso a la plataforma.</p>
        <ButtonCustom
          type="submit"
          className="PersonalDataMatiVerificationForm__submit"
          size="lg"
          block={true}
          variant="primary"
          loading={loading}
          onClick={onSubmit}
        >
          {'Continuar con verificacion pendiente'}
        </ButtonCustom>
      </div>
    )
  }

  return (
    <div className="PersonalDataMatiVerificationForm">
      {
        matiStatus.waiting_for_auto ? (
          <>
            <Spinner className="VerificationSpinner"/>
            <p>Estamos procesando tus datos, un momento por favor.</p>
          </>
        ) : (
        matiStatus.waiting_for_manual && retry === false ? (
          <div className="PersonalDataMatiVerificationForm">
          {/*<Spinner className="VerificationSpinner"/>*/}
          <p>No pudimos aprobar tus datos automáticamente</p>
          <p>Puede continuar pero tendremos que revisar sus datos manualmente antes de darle acceso a la plataforma.</p>
          {/*matiStatus.can_retry && (
          <>
            <p>O podes <a onClick={retryInputHandler} href='#'>reintentarlo</a></p>
          </>
        )*/}
          <ButtonCustom
            type="submit"
            className="PersonalDataMatiVerificationForm__submit"
            size="lg"
            block={true}
            variant="primary"
            loading={loading}
            onClick={onSubmit}
          >
            {'Continuar con verificacion pendiente'}
          </ButtonCustom>
        </div>
        ) : (
        matiStatus.waiting_for_input && retry === false ? (
          <>
          <Spinner className="VerificationSpinner"/>
          <p>
            Estamos esperando que completes el formulario de validación en la ventana emergente, si lo cerraste
            podes&nbsp;
            <a onClick={retryInputHandler} href='#'>volver a validar</a>
          </p>
        </>
        ) : (
        matiStatus.can_retry ? (
          <div className='PersonalDataMatiVerificationForm'>
          {
            matiStatus.rejected_attempts > 0 && (
              <p>Su identidad no pudo ser validada, le recomendamos revisar sus datos personales y asegurarse de
                estar usando el DNI correcto antes de reintentar.</p>
            )
          }
          <div className="PersonalDataMatiVerificationForm__mati_container">
            <metamap-button
              clientid={matiStatus.client_id}
              flowId={matiStatus.flow_id}
              metadata={JSON.stringify(metadata)}
              onClick={() => setActive(true)}
            />
          </div>
        </div>
        ) : (
          <div className="PersonalDataMatiVerificationForm">
          <p>Su identidad no pudo ser validada, le recomendamos revisar sus datos personales y asegurarse de
            estar usando el DNI correcto.</p>
          <p>Puede continuar sin verificar pero tendremos que revisar sus datos manualmente antes de darle
            acceso a la plataforma.</p>
          <ButtonCustom
            type="submit"
            className="PersonalDataMatiVerificationForm__submit"
            size="lg"
            block={true}
            variant="primary"
            loading={loading}
            onClick={onSubmit}
          >
            {'Continuar sin verificar'}
          </ButtonCustom>
        </div>
        ))))
      }
    </div>
  );
}

PersonalDataMatiVerificationForm.propTypes = {
  onSubmit: PropTypes.func,
  onStepChange: PropTypes.func,
  initialValues: PropTypes.shape({

  }),
  submitLabel: PropTypes.string,
  nextStepLabel: PropTypes.string,
  loading: PropTypes.bool,
  readonly: PropTypes.bool,
  singleStep: PropTypes.bool,
  matiStatus: PropTypes.shape({
    flow_id: PropTypes.string.isRequired,
    client_id: PropTypes.string.isRequired,
    waiting_for_auto: PropTypes.bool.isRequired,
    waiting_for_manual: PropTypes.bool.isRequired,
    waiting_for_input: PropTypes.bool.isRequired,
    waiting_for_input_count: PropTypes.number.isRequired,
    validated: PropTypes.bool.isRequired,
    can_retry: PropTypes.bool.isRequired,
    attempts: PropTypes.number.isRequired,
    rejected_attempts: PropTypes.number.isRequired,
  }).isRequired,
}

export default PersonalDataMatiVerificationForm;