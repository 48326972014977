import { combineReducers } from "redux";
import auth from "./auth";
import menu from "./menu";
import common from './common';
import external from './external';

export default combineReducers({
    auth,
    menu,
    common,
    external
})
