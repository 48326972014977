import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {setRedirectUrl} from "../store/actions/external";

export default () => {

  const query = new URLSearchParams(window.location.search);
  const url_from_query = query.get('redirect_url')
  const url_from_store = useSelector(store => store.external.redirect_url);
  const redirect_url = url_from_query || url_from_store;
  const dispatch = useDispatch();

  useEffect(() => {
    if (redirect_url) {
      dispatch(setRedirectUrl(redirect_url));
    }
  }, [redirect_url])
}
