import React, {useCallback, useEffect, useState} from 'react';
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
import './VerifyEmailPage.scss';
import {useLocation, useHistory} from 'react-router-dom';
import authAPI from "../../api/authAPI";
import ButtonCustom from "../../components/forms/ButtonCustom";
import {useSelector} from "react-redux";
import {FaCheckCircle} from "react-icons/all";

const VerifyEmailPage = () => {
  const [loading, setLoading] = useState(true);
  const [feedback, setFeedback] = useState(null);
  const [done, setDone] = useState(false);
  const loggedIn = useSelector(state => state.auth.isAuth)
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    authAPI.verifyEmail(query.get('email'), query.get('hash'))
      .then((response) => {
        setDone(true);
        setFeedback({success: true, message: 'Su email fue verificado correctamente'});

      }).catch((e) => {
        if(e.response.status === 422){
          setFeedback({success: false, message: 'No pudimos verificar su email'});
        }else{
          setFeedback({success: false, message: 'Ocurrio un error al verificar su email'});
        }
      }).then(() => {
        setLoading(false);
      });
  }, []);

  const goBackHandler = useCallback((e) => {
    history.replace('/');
  }, []);

  return (
    <EmptyLayout>
      <div className="VerifyEmailPage auth-content__inner">
        <div className="verify-email">
          <h3 className="VerifyEmailPage__title">
            Verificación de email
          </h3>
          <div className="VerifyEmailPage__content">
            { done === false
              ? (
                <div>
                  <div>
                    <h5>
                      {
                        loading
                          ? "Estamos verificando su email"
                          : feedback.message
                      }
                    </h5>
                  </div>
                  <ButtonCustom
                    type="button"
                    onClick={goBackHandler}
                    className="auth-btn VerifyEmailPage__back-btn"
                    size="lg"
                    block={true}
                    variant="primary"
                    loading={loading}
                  >
                    Continuar
                  </ButtonCustom>
                </div>
              ) : (
                <div className="VerifyEmailPage__success-content">
                  <h5>
                    Validacion de email correcta
                  </h5>
                  <FaCheckCircle className="VerifyEmailPage__success-content-icon" size={50}/>
                  {
                    loggedIn
                      ? (<h6>
                        Puede continuar el proceso en la pestaña donde comenzo, o continuarlo desde esta.
                      </h6>)
                      : (
                        <h6>
                          Puede continuar el proceso en el dispositivo donde comenzo, o retomarlo desde aqui.
                        </h6>
                      )
                  }
                  <a onClick={goBackHandler} href="#">
                    {loggedIn ? 'Continuar en esta pestaña' : 'Retomar en esta pestaña' }
                  </a>
                </div>
              )
            }
          </div>
        </div>
      </div>
    </EmptyLayout>
  );
}

VerifyEmailPage.propTypes = {

};

export default VerifyEmailPage;
