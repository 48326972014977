import {SET_EXTERNAL_URL} from "../types/external";

const initialState = {
    redirect_url : null
}
export default (state = initialState, action) => {

    switch (action.type) {
        case SET_EXTERNAL_URL: {
            return {
                ...state, redirect_url: action.payload
            }
        }
        default: {
            return state
        }
    }
}