import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProfile, logout} from "../store/actions/auth";

const usePlatformRedirection = () => {
  const [intendedRedirection, setIntendedRedirection] = useState(null);
  const {isUserLoading, isAuth, tokens, user} = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const urlParams = new URLSearchParams(window.location.search);

  const redirectPath = urlParams.get('redirect_path') ? '/' + urlParams.get('redirect_path').replace(/^\/+|\/+$/g, '') : null;

  if(
    redirectPath
    && urlParams.get('redirect_platform')
    && (
      intendedRedirection === null
      || intendedRedirection.redirect_path !== redirectPath
      || intendedRedirection.redirect_platform !== urlParams.get('redirect_platform'))
    )
  {
    setIntendedRedirection({
      redirect_path: redirectPath,
      redirect_platform: urlParams.get('redirect_platform'),
    });
  }

  useEffect(() => {
    if(!isUserLoading && isAuth && intendedRedirection && tokens.access_token){
      const platform = user.platforms.find(platform => platform.key === intendedRedirection.redirect_platform);

      if(platform){
        getProfile()(dispatch)
          .then(() => {
            const redirect = `${platform.url}${intendedRedirection.redirect_path}?access_token=${tokens.access_token}`;
            window.location.replace(redirect);
          })
          .catch(() => logout()(dispatch))
      }else{
        setIntendedRedirection(null);
      }
    }
  }, [isAuth, isUserLoading, intendedRedirection, tokens.access_token])
}

export default usePlatformRedirection;