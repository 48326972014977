import React, {useState} from 'react';
import {Button, Form} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import './RegisterPage.scss';
import LoginHeaderImage from '../../assets/images/login-header-2.svg';
import InputCustom from "../../components/forms/InputCustom";
import {login, register, getProfile} from "../../store/actions/auth";
import route from "../../helpers/route";
import {connect} from "react-redux";
import {useFormik} from "formik";
import EmptyLayout from "../layouts/EmptyLayout/EmptyLayout";
import * as Yup from "yup";
import commonAPI from "../../api/commonAPI";
import useDebouncedCallback from "../../hooks/useDebouncedCallback";
import useAlerts from "../../hooks/useAlerts";
import ButtonCustom from "../../components/forms/ButtonCustom";
import isHubspotValid from '../../helpers/hubspot-email-validation';
import Spinner from '../../components/Spinner/Spinner';

const RegisterPage = ({register, login, getProfile}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState(null);
  const alerts = useAlerts();
  const query = new URLSearchParams(window.location.search);
  const redirect_url = query.get('redirect_url');
  const forzarLoader = query.has('registro-token');

  const checkEmail = useDebouncedCallback( email => {
    return commonAPI.checkEmailUnavailable(email);
  }, 500,[commonAPI.checkEmailUnavailable])

  const checkLocalStorage = setInterval(() => {
    if (query.has('registro-token') && getItemConExpiracion("ite") === null) {
      const accessToken = query.get('registro-token');
      const refreshToken = query.get('registro-refresh-token');
  
      const jsonString = JSON.stringify({
        auth: JSON.stringify({
          tokens: {
            token_type: "Bearer",
            expires_in: 64800,
            access_token: accessToken,
            refresh_token: refreshToken
          },
          isAuth: true,
          remember: true,
          user: null
        }),
        _persist: JSON.stringify({
          version: 3,
          rehydrated: true
        })
      });
  
      localStorage.setItem('persist:dc-onboarding.auth', jsonString);
      setItemConExpiracion('ite', 's', 5);
      history.push('/registro');
      window.location.reload();
    }else{
      getItemConExpiracion("ite");
      clearInterval(checkLocalStorage);
    }
  }, 1000);  
  setInterval(() => { getItemConExpiracion("ite"); }, 2500);

  function getItemConExpiracion(key) {
    const itemStr = localStorage.getItem(key);
  
    if (!itemStr) {
      return null;
    }
  
    const item = JSON.parse(itemStr);
    const now = new Date();
  
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
  
    return item.value;
  }

  function setItemConExpiracion(key, value, expiryInSeconds) {
    const now = new Date();
    const expiryTime = now.getTime() + expiryInSeconds * 1000;
  
    const item = {
      value: value,
      expiry: expiryTime
    };
  
    localStorage.setItem(key, JSON.stringify(item));
  }

  const formik = useFormik({
    initialValues: {
      email: '',
      email_confirmation: '',
      password: '',
    },
    onSubmit: async (values) => {
      setLoading(true);
      setFeedback(null);

      try{
        await register(values.email, values.password, redirect_url);

        try{
          await login(values.email, values.password, true);
          history.replace({
            pathname: route('onboarding'),
            search: redirect_url ? `?redirect_url=${redirect_url}` : ''
          });
        }catch(e){
          alerts.add({title: 'Error', message: 'Ocurrio un error al ingresar a su cuenta'});
          history.replace(route('login'));
        }
      }catch(e){
        setFeedback({message: 'Ocurrio un error al registrarse.', success: false});
        setLoading(false);
      }
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email('Email invalido')
        .required('Obligatorio')
        .test(
          'is-hubspot-valid',
          'Email invalido.',
          isHubspotValid
        )
        .test(
          'is-available',
          'El correo ya esta registrado, ¿Ya tiene cuenta?',
          async email => ((await checkEmail(email)).data === false)
        ),
      email_confirmation: Yup.string()
        .email('Email invalido')
        .required('Obligatorio')
        .oneOf([Yup.ref('email'), null], 'Los emails no coinciden'),
      password: Yup.string()
        .required('Obligatorio').min(8, 'Minimo 8 caracteres'),
    }),
  });

  if(forzarLoader){
    return (
      <div className="global-loading">
        <Spinner size={100}/>
      </div>
    );
  }

  return (
    <EmptyLayout>
      <div className="RegisterPage auth-content__inner">
        <div className="register">
          <div className="">
            <img className="register__header" src={LoginHeaderImage} alt="logo"/>
          </div>
          <h3 className="register__title auth-title">
            Registro de comercio
          </h3>
          <Form className="register__form" onSubmit={formik.handleSubmit}>
            <Form.Group>
              <InputCustom
                title="Ingresá tu mail"
                autoComplete="off"
                onChange={formik.handleChange}
                name="email"
                value={formik.values.email}
                id="email"
                type="email"
                disabled={loading}
                error={formik.touched.email && formik.errors.email}
              />
            </Form.Group>

            <Form.Group>
              <InputCustom
                title="Confirmá tu mail"
                autoComplete="off"
                onChange={formik.handleChange}
                name="email_confirmation"
                value={formik.values.email_confirmation}
                id="email_confirmation"
                type="email"
                disabled={loading}
                error={formik.touched.email_confirmation && formik.errors.email_confirmation}
              />
            </Form.Group>

            <Form.Group>
              <InputCustom
                title="Contraseña"
                autoComplete="off"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                id="password"
                type="password"
                disabled={loading}
                error={formik.touched.password && formik.errors.password}
              />
            </Form.Group>

            <div>
              {feedback && (
                <div className={`auth__feedback ${feedback.success ? 'auth__feedback--success' : 'auth__feedback--error'}`}>
                  {feedback.message}
                </div>
              )}

              <ButtonCustom
                type="submit"
                className="register__btn auth-btn"
                size="lg"
                block={true}
                variant="primary"
                loading={loading}
              >
                Registrar
              </ButtonCustom>
            </div>

            <div className="register__option-container">
              <h5>
                <br/>
                ¿Ya tenes cuenta?
                <Link className="register__option register__option--login" to={route('login')}>
                  Iniciar sesión
                </Link>
              </h5>
            </div>
          </Form>
        </div>
      </div>
    </EmptyLayout>
  );
}

RegisterPage.propTypes = {

};

const mapDispatchToProps = {
  register,
  login,
  getProfile,
};

export default connect(null, mapDispatchToProps)(RegisterPage);